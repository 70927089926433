<template>
	<headerNav></headerNav>
	<div class="home-page">
		<div class="part-1">
			<div style="height:80vh;"></div>
			<!-- <div class="c-fff text-center p1-title">{{store.state.lang.marketplaceText}}</div> -->
			<!-- <div class="c-fff text-center p1-desc">{{store.state.lang.metaceresText}}</div> -->
			<div style="height:5vh;"></div>
			<div class="container">
				<div class="row">
					<div class="col-md-8 col-sm-8 col-8 h-title1">
						{{store.state.lang.homeTitle}}
					</div>
					<div class="col-md-4 col-sm-4 col-4" style="text-align: right;">
						<img class="h-1-img" :src="require('../../assets/img/icon/h_1.png')" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="c-fff h-desc1 col-12">
					{{store.state.lang.homeDesc}}
				</div>
			</div>
			<div class="enter-btn ls-flex-ajc" @click="toPage('/nft')">
				{{store.state.lang.enterApp}}
			</div>
			<div class="h-view ls-flex-ac">
				<div class="c-fff">DAO <br /> {{store.state.lang.overviewText}}</div>
			</div>
			<div style="padding: 0 25px;box-sizing: border-box;">
				<div class="row h-view-info">
					<div class="col-md-4 col-sm-4 col-3 ls-flex-ajc">
						<img class="h-view-icon" :src="require('../../assets/img/icon/icon_house.png')" alt="">
					</div>
					<div class="col-md-4 col-sm-4 col-4 c-fff h-view-sub ls-flex-ajc">{{store.state.lang.treasury}}</div>
					<div class="col-md-4 col-sm-4 col-5 c-fff h-view-val ls-flex-ajc">$0</div>
				</div>
				<!-- <div class="row h-view-info">
					<div class="col-md-4 col-sm-4 col-3 ls-flex-ajc">
						<img class="h-view-icon" :src="require('../../assets/img/icon/icon_line.png')" alt="">
					</div>
					<div class="col-md-4 col-sm-4 col-4 c-fff h-view-sub ls-flex-ajc">{{store.state.lang.ROI}}</div>
					<div class="col-md-4 col-sm-4 col-5 c-fff h-view-val ls-flex-ajc">0</div>
				</div> -->
				<div class="row h-view-info">
					<div class="col-md-4 col-sm-4 col-3 ls-flex-ajc">
						<img class="h-view-icon" :src="require('../../assets/img/icon/icon_user.png')" alt="">
					</div>
					<div class="col-md-4 col-sm-4 col-4 c-fff h-view-sub ls-flex-ajc">{{store.state.lang.members}}</div>
					<div class="col-md-4 col-sm-4 col-5 c-fff h-view-val ls-flex-ajc">0</div>
				</div>
				<div class="row h-view-info">
					<div class="col-md-4 col-sm-4 col-3 ls-flex-ajc">
						<img class="h-view-icon" :src="require('../../assets/img/icon/icon_zuan.png')" alt="">
					</div>
					<div class="col-md-4 col-sm-4 col-4 c-fff h-view-sub ls-flex-ajc">{{store.state.lang.portfolio}}</div>
					<div class="col-md-4 col-sm-4 col-5 c-fff h-view-val ls-flex-ajc">0</div>
				</div>
			</div>

			<div class="row" style="margin-top: 10vh;">
				<div class="col-md-8 col-sm-8 col-8 h-title1">
					{{store.state.lang.newOrganizetion}}
				</div>
				<div class="col-md-4 col-sm-4 col-4" style="text-align: right;">
					<img class="h-1-img" :src="require('../../assets/img/icon/h_2.png')" alt="">
				</div>
			</div>
			<div class="row mt-5">
				<div class="c-fff h-desc1 col-12">
					{{store.state.lang.newOrganizetionDesc}}
				</div>
			</div>
			<div class="nav-btn ls-flex-ac mt-5 mb-5" @click="toPage('/stake')">
				{{store.state.lang.stackeText}}
			</div>
			
			<div class="stake-box pos-r">
				<div class="w-100 text-center">
					<img style="width: 80%;" :src="require('../../assets/img/icon/bg_2.png')" alt="">
				</div>
				<div class="stake-info c-fff">
					{{store.state.lang.stackeDesc}}
				</div>
			</div>

			<div class="stake-box pos-r">
				<img class="w-100" :src="require('../../assets/img/icon/bg_3.png')" alt="">

				<div class="stake-info c-fff">
					<div class="nav-btn ls-flex-ac" @click="toPage('/nft')">
						{{store.state.lang.bullNftText}}
					</div>
					<div class="mt-3" style="padding: 0 30px;box-sizing: border-box;">
						{{store.state.lang.bullNftDesc}}
					</div>
				</div>
			</div>

			<div class="nav-btn ls-flex-ac mb-3" @click="open()">
				{{store.state.lang.governanceText}} 
			</div>
			<div class="stake-box pos-r">
				<div class="w-100 text-center">
					<img style="width: 80%;" :src="require('../../assets/img/icon/bg_4.png')" alt="">
				</div>
				<div class="stake-info c-fff">
					{{store.state.lang.governanceDesc}} 
				</div>
			</div>

			<div class="row ls-flex-ac">
				<div class="col-md-8 col-sm-8 col-8 h-title1">
					{{store.state.lang.howToJoin}}
				</div>
				<div class="col-md-4 col-sm-4 col-4" style="text-align: right;">
					<img class="h-1-img" :src="require('../../assets/img/icon/h_3.png')" alt="">
				</div>
			</div>
			<div class="ls-flex-ajb ls-flex-1 p-3">
				<img class="vc-pool-img" :src="require('../../assets/img/icon/icon_d_red.png')" alt="">
				<div class="vc-pool c-fff ls-flex-1">{{store.state.lang.vcPoolText}}</div>
				<div class="mint-btn ls-flex-ajc"  @click="open()">{{store.state.lang.mintText}}</div>
			</div>
			<div class="stake-info c-fff h5_mb_3">
				Mint W3B with Crypto Assets. Currently supports: BTC, ETH, BNB, FIL, etc.
			</div>

			<el-divider />

			<div class="paper h5_mt_3">
				<div class="ls-flex-ajb ls-flex-1" style="height: 60px;padding: 0 15px;box-sizing: border-box;">
					<img class="vc-pool-img" :src="require('../../assets/img/icon/icon_d_red.png')" alt="">
					<div class="vc-pool c-fff ls-flex-1">{{store.state.lang.bondText}}</div>
					<div class="mint-btn ls-flex-ajc" @click="toPage('/bond')">{{store.state.lang.mintText}}</div>
				</div>
				<div class="stake-info c-fff p-3">
					{{store.state.lang.bondDesc}}
				</div>
			</div>

			<div class="h-title1 mt-5">{{store.state.lang.community}}</div>
			<div>
				<img class="w-100" :src="require('../../assets/img/icon/w3_dao.png')" alt="">
			</div>

			<div class="row mt-5">
				<div class="col-md-6 col-sm-6 col-6 text-center w3-dao">
					<img class="w3-dao-icon" :src="require('../../assets/img/icon/icon_1_red.png')" alt="">
					<div class="w3-dao-name">{{store.state.lang.twitterMember}}</div>
					<el-divider />
					<div class="w3-dao-val">0</div>
				</div>
				<div class="col-md-6 col-sm-6 col-6 text-center w3-dao">
					<img class="w3-dao-icon" :src="require('../../assets/img/icon/icon_4_red.png')" alt="">
					<div class="w3-dao-name">{{store.state.lang.discordMember}}</div>
					<el-divider />
					<div class="w3-dao-val">0</div>
				</div>
			</div>

			<div class="row ls-flex-ac mt-5">
				<div class="col-md-8 col-sm-8 col-8 h-title1">{{store.state.lang.ecology}}</div>
				<div class="col-md-4 col-sm-4 col-4" style="text-align: right;">
					<img class="h-1-img" :src="require('../../assets/img/icon/h_4.png')" alt="">
				</div>
			</div>
			
			<div class="row mt-4">
				<div class="col-lg-6 col-md-6 col-sm-12 col-12" @click="open()">
					<img class="w-100" :src="require('../../assets/img/icon/banner_1.png')" alt="">
					<div class="stake-info c-fff mt-4">
						{{store.state.lang.ecologyDesc1}}
					</div>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 col-12" @click="open()">
					<img class="w-100" :src="require('../../assets/img/icon/banner_2.png')" alt="">
					<div class="stake-info c-fff mt-4">
						{{store.state.lang.ecologyDesc2}}
					</div>
				</div>
			</div>
			
			<div class="row mt-4">
				<div class="col-lg-6 col-md-6 col-sm-12 col-12" @click="open()">
					<img class="w-100" :src="require('../../assets/img/icon/banner_3.png')" alt="">
					<div class="stake-info c-fff mt-4">
						{{store.state.lang.ecologyDesc3}}
					</div>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 col-12" @click="open()">
					<img class="w-100" :src="require('../../assets/img/icon/banner_4.png')" alt="">
					<div class="stake-info c-fff mt-4">
						{{store.state.lang.ecologyDesc4}}
					</div>
				</div>
			</div>

			<div class="ls-flex-ajb mt-5">
				<div class="h-title1">{{store.state.lang.news}}</div>
				<img @click="open()" style="width: 100px;cursor: pointer;" :src="require('../../assets/img/icon/icon_btn_right.png')"
					alt="">
			</div>

			<div class="paper news-list mt-3" v-for="(item,index) in store.state.lang.newsList" key="index">
				<div class="news-title">{{item.title}}</div>
				<div class="news-date">{{item.date}}</div>
				<div class="news-info">
					{{item.info}}
				</div>
			</div>
			<div class="h-80" id='faqList'></div>
			<div class="ls-flex-ajb">
				<div class="h-title1">{{store.state.lang.commonFAQ}}</div>
				<!-- <img @click="open()" style="width: 100px;cursor: pointer;" :src="require('../../assets/img/icon/icon_btn_right.png')"
					alt=""> -->
			</div>
			<div class="mt-5">
				<div class="ls-collapse" v-for="(item,index) in store.state.lang.faqList" :key="index" @click="changeCollapse(index)">
					<div class="ls-collapse-header ls-flex-ajb">
						<div>{{item.title}}</div>
						<img :src="item.state?require('../../assets/img/icon/c_s_right.png'):require('../../assets/img/icon/c_k_right.png')" alt="">
					</div>
					<div class="ls-collapse-body" v-if="item.state" v-for="(val,key) in item.info">{{val}}</div>
				</div>
			</div>
		</div>
		
		<div class="container" style="background-color: #111;">
			<div class="row hot-nav mt-5">
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_1.png')" alt="">
					</a>
				</div>
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_2.png')" alt="">
					</a>
				</div>
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_3.png')" alt="">
					</a>
				</div>
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_4.png')" alt="">
					</a>
				</div>
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_5.png')" alt="">
					</a>
				</div>
				<div class="col-md-2 com-sm-4 col-4">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_6.png')" alt="">
					</a>
				</div>
			</div>
			<div class="row home-footer text-center mt-5">
				<div class="col-md-4 col-sm-4 col-4">
					<div class="f-title">{{store.state.lang.products}}</div>
					<div class="f-item" @click="toPage('/nft')">{{store.state.lang.bullNftText}}</div>
					<div class="f-item"  @click="open()">{{store.state.lang.vcPoolText}}</div>
					<div class="f-item" @click="toPage('/bond')">{{store.state.lang.bondText}}</div>
				</div>
				<div class="col-md-4 col-sm-4 col-4">
					<div class="f-title">{{store.state.lang.learn}}</div>
					<div class="f-item" @click="open()">{{store.state.lang.docsText}}</div>
					<div class="f-item" @click="open()">MEDIUM</div>
				</div>
				<div class="col-md-4 col-sm-4 col-4">
					<div class="f-title">{{store.state.lang.contactUs}}</div>
					<div class="f-item" @click="open()">DISCORD</div>
					<div class="f-item" @click="open()">EMAIL</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {ref} from 'vue';
	import {useRouter} from 'vue-router';
	import {useStore} from 'vuex';
	import headerNav from './components/header.vue';
	import { ElMessage } from 'element-plus';
	
	const store = useStore()
	const router = useRouter();
	
	const toPage = path => router.push(path);
	
	const changeCollapse = (index) => {
		store.state.lang.faqList[index].state = !store.state.lang.faqList[index].state;
	}
	
	const open = () => {
		ElMessage({
			message:store.state.lang.commingSoon,
			type:store.state.lang.warningTips
		});
	}
</script>

<style scoped>
	.home-page {
		padding: 10px 0;
		box-sizing: border-box;
		background-color: #000;
	}
	
	.home-page .part-1 {
		background-image: url('../../assets/img/icon/index_banner.png');
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.home-page .h-title1 {
		font-size: 60px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 91px;
	}

	.p1-title {
		font-size: 42px;
		font-weight: bold;
	}

	.p1-desc {
		font-size: 22px;
	}

	.h-desc1 {
		font-size: 24px;
	}

	.vc-pool {
		font-size: 36px;
		font-weight: 500;
	}

	.h-1-img {
		width: 180px;
	}

	.enter-btn,
	.mint-btn {
		width: 140px;
		height: 50px;
		margin: 50px auto;
		background-image: url('../../assets/img/icon/icon_btn.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		font-size: 20px;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.nav-btn {
		width: 50%;
		height: 100px;
		padding: 0 50px;
		margin: 0 auto;
		box-sizing: border-box;
		background-image: url('../../assets/img/icon/icon_nav_right.png');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: contain;
		font-size: 22px;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}

	.h-view {
		height: 600px;
		background-image: url('../../assets/img/icon/bg_1.png');
		background-position: right;
		background-repeat: no-repeat;
		background-size: contain;
		font-size: 80px;
		font-weight: bold;
	}

	.h-view-info {
		/* height: 80px; */
		padding: 25px 0;
		border-bottom: 2px solid #260000;
	}

	.h-view-info .h-view-icon {
		width: 80px;
	}

	.h-view-info .h-view-sub {
		font-size: 30px;
		line-height: 60px;
	}

	.h-view-info .h-view-val {
		font-size: 36px;
		line-height: 36px;
	}

	.stake-box .stake-info {
		position: absolute;
		top: 10px;
		left: 0;
		width: 100%;
		font-size: 24px;
		padding: 0 15px;
	}

	.vc-pool-img {
		width: 36px;
		margin-right: 15px;
	}

	:deep .el-divider--horizontal {
		border-top: 1px solid #FF0000;
	}

	.w3-dao .w3-dao-icon {
		width: 80px;
	}

	.w3-dao .w3-dao-name {
		font-size: 30px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 37px;
	}

	.w3-dao .w3-dao-val {
		font-size: 53px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 37px;
	}

	.news-list {
		padding: 50px;
		box-sizing: border-box;
	}

	.news-list .news-title {
		font-size: 53px;
		font-weight: 600;
		color: #E2E2E2;
		line-height: 67px;
	}

	.news-list .news-date {
		margin: 30px 0;
		font-size: 20px;
		font-weight: 600;
		color: #9F0E0E;
		line-height: 37px;
	}

	.news-list .news-info {
		font-size: 30px;
		font-weight: 600;
		color: #E2E2E2;
		line-height: 40px;
	}
	.ls-collapse{
		border-bottom: 1px solid #454545;
		cursor: pointer;
	}
	.ls-collapse .ls-collapse-header{
		padding: 20px 0;
		font-size: 30px;
		font-weight: 500;
		color: #E2E2E2;
	}
	.ls-collapse .ls-collapse-header img{
		width: 50px;
	}
	.ls-collapse .ls-collapse-body{
		padding-bottom: 20px;
		margin-top: 10px;
		font-size: 20px;
		color: #E2E2E2;
		text-align: justify;
	}
	.hot-nav a{
		width: 50px;
		height: 50px;
		background-color: #9F0E0E;
		border-radius: 50%;
		margin: 0 auto 20px;
	}
	.hot-nav a img{
		width: 30px;
	}
	.home-footer{
		
	}
	.home-footer .f-title{
		margin-bottom: 15px;
		font-size: 24px;
		font-weight: 500;
		color: #9F0E0E;
		line-height: 37px;
	}
	.home-footer .f-item{
		font-size: 20px;
		color: #E2E2E2;
		line-height: 37px;
		cursor:pointer;
	}
	.h-80{
		height:80px;
	}
	@media (max-width:768px) {
		.container{
			padding:15px;
			box-sizing:border-box;
		}
		.h-80{
			height:60px;
		}
		.h5_mt_3{
			margin-top:30px;
		}
		.h5_mb_3{
			margin-bottom:30px;
		}
		.p1-title {
			font-size: 24px;
			font-weight: bold;
		}

		.p1-desc {
			font-size: 12px;
		}

		.home-page .h-title1 {
			font-size: 32px;
			color: #FFFFFF;
			line-height: 50px;
		}

		.h-1-img {
			width: 110px;
		}

		.h-desc1,
		.vc-pool {
			font-size: 18px;
		}

		.h-view {
			height: 300px;
			font-size: 40px;
			font-weight: 500;
			background-position: 100px center;
		}

		.h-view-info {
			padding: 15px 0;
		}

		.h-view-info .h-view-icon {
			width: 50px;
		}

		.h-view-info .h-view-sub {
			font-size: 20px;
		}

		.h-view-info .h-view-val {
			font-size: 20px;
		}

		.nav-btn {
			height: 60px;
			width: 100%;
		}

		.mint-btn {
			width: 90px;
			height: 30px;
			font-size: 18px;
		}

		.stake-box .stake-info {
			font-size: 16px;
		}

		.vc-pool-img {
			width: 20px;
		}

		.w3-dao .w3-dao-icon {
			width: 50px;
		}

		.w3-dao .w3-dao-name {
			font-size: 18px;
		}

		.w3-dao .w3-dao-val {
			font-size: 23px;
		}

		:deep .el-divider--horizontal {
			margin: 0;
		}

		.news-list {
			padding: 20px;
		}

		.news-list .news-title {
			font-size: 23px;
			line-height: 32px;
		}

		.news-list .news-date {
			font-size: 13px;
			color: #9F0E0E;
			margin: 0;
		}

		.news-list .news-info {
			font-size: 14px;
			line-height: 24px;
			font-weight: 400;
		}
		.ls-collapse{
			/* height: 80px; */
		}
		.ls-collapse .ls-collapse-header{
			padding: 15px 0;
			font-size: 18px;
		}
		.ls-collapse .ls-collapse-header img{
			width: 30px;
		}
		.ls-collapse .ls-collapse-body{
			font-size: 14px;
			color: #E2E2E2;
		}
		.hot-nav a{
			width: 40px;
			height: 40px;
			background-color: #9F0E0E;
			border-radius: 50%;
		}
		.hot-nav a img{
			width: 20px;
		}
		.home-footer .f-title{
			font-size: 16px;
			line-height: 28px;
		}
		.home-footer .f-item{
			font-size: 14px;
			line-height: 24px;
		}
	}
</style>
