<template>
	<div class="ls-nav ls-flex-ajb">
		<div class="text-center logo">
			<img :src="require('../../../assets/img/icon/logo_menu.png')" alt="" class="user-img">			
		</div>
		<div class="ls-flex-1"></div>
		<div class="ls-nav-bar ls-flex-ajb">
			<div class="nav-bar-item ls-flex-ajc" @click="open()">
				<a>
					{{store.state.lang.vcPoolText}}
				</a>
			</div>
			<div class="nav-bar-item ls-flex-ajc">
				<router-link to="/nft">
					{{store.state.lang.bullNftText}}
				</router-link>
			</div>
			<div class="nav-bar-item ls-flex-ajc">
				<router-link to="/stake">
					{{store.state.lang.stackeText}}
				</router-link>
			</div>
			<div class="nav-bar-item ls-flex-ajc" @click="scrollFaq()">
				<a>
					{{store.state.lang.faqText}}
				</a>
			</div>
			<div class="nav-bar-item ls-flex-ajc" @click="open()">
				<a>
					{{store.state.lang.guidText}}
				</a>
			</div>
			<div class="h5_menu text-center" @click="showMenu()">
				<img :src="!drawerState?require('../../../assets/img/icon/icon_menu.png'):require('../../../assets/img/icon/icon_c.png')" alt="">
			</div>
			<div class="enter-btn-box" @click="toPage('/nft')">
				<div class="enter-btn ls-flex-ajc">{{store.state.lang.enterApp}}</div>
			</div>
		</div>
		<LangBtn></LangBtn>
	</div>
	
	<el-drawer
	    v-model="drawerState"
	    direction="ttb"
		customClass="drawerCustomClass"
		modalClass="drawerModalClass"
	  >
	    <div class="h5_nav_box">
			<div class="h5_nav ls-flex-ac" @click="open()">
				<a>
					{{store.state.lang.vcPoolText}}
				</a>
			</div>
			<div class="h5_nav ls-flex-ac">
				<router-link to="/nft">
					{{store.state.lang.bullNftText}}
				</router-link>
			</div>
			<div class="h5_nav ls-flex-ac">
				<router-link to="/stake">
					{{store.state.lang.stackeText}}
				</router-link>
			</div>
			<div class="h5_nav ls-flex-ac" @click="scrollFaq()">
				<a>
					{{store.state.lang.faqText}}
				</a>
			</div>
			<div class="h5_nav ls-flex-ac" @click="open()">
				<a>
					{{store.state.lang.guidText}}
				</a>
			</div>
		</div>
	</el-drawer>
	  
</template>

<script setup>
	import {ref} from 'vue';
	import {useRouter} from 'vue-router';
	import {useStore} from 'vuex';
	import LangBtn from '@/components/lang-btn/lang-btn.vue';
	import { ElMessage } from 'element-plus';
	
	const router = useRouter();
	const store = useStore();
	
	const drawerState = ref(false);
	
	const open = () => {
		ElMessage({
			message:store.state.lang.commingSoon,
			type:store.state.lang.warningTips
		});
	}
	
	const toPage = path => router.push(path);
	
	const showMenu = ()=>{
		drawerState.value = !drawerState.value;
	}
	
	const scrollFaq = ()=>{
		document.getElementById("faqList").scrollIntoView();
		drawerState.value = false;
	}
</script>

<style scoped>
	.ls-nav{
		position: fixed;
		top:0;
		left: 0;
		z-index: 2003;
		width: 100%;
		height: 80px;
		padding: 0 30px 0 80px;
		box-sizing: border-box;
		background: #800909;
	}
	.ls-nav .logo img{
		height: 60px;
		cursor: pointer;
	}
	.ls-nav .ls-nav-bar{
		/* width: 60%; */
		height: 80px;
	}
	.ls-nav-bar .nav-bar-item{
		width: 150px;
		height: 40px;
	}
	.ls-nav-bar .enter-btn-box .enter-btn{
		width: 140px;
		height: 50px;
		margin-left: 10px;
		background-image: url('../../../assets/img/icon/icon_btn.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		font-size: 20px;
		font-weight: 500;
		color: #FFFFFF;
		cursor: pointer;
	}
	.ls-nav-bar .h5_menu{
		display: none;
		width: 80px;
		height: 40px;
		background-image: url('../../../assets/img/icon/icon_btn.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size:80px 40px;
		cursor: pointer;
		line-height: 35px;
	}
	.ls-nav-bar .h5_menu img{
		width: 30px;
	}
	.ls-nav-bar .nav-bar-item a{
		display: block;
		color: #e5e5e5;
		text-decoration: none;
		border-radius: 26px;
		padding: 0 15px;
		box-sizing: border-box;
		font-size:28px;
		font-family: Axiforma-SemiBold, Axiforma;
		font-weight: 500;
		cursor: pointer;
	}
	.ls-nav-bar .nav-bar-item a.router-link-active{
		color: #fff;
		background: #620000;
		border: 1px solid #FF0000;
	}
	.ls-nav-bar .nav-bar-item:hover{
		background: #620000;
		border: 1px solid #FF0000;
		border-radius:20px;
	}
	.h5_nav_box{
		padding-bottom: 30px;
		box-sizing: border-box;
	}
	.h5_nav{
		height: 50px;
	}
	.h5_nav a{
		padding: 0 30px;
		box-sizing: border-box;
		color: #fff;
		font-size:24px;
		font-weight: 500;
		text-decoration: none;
	}
	@media (max-width:768px) {
		.ls-nav-bar .h5_menu{
			display: block;
		}
		.nav-bar-item{
			display: none;
		}
		.ls-nav{
			height: 60px;
			padding:0 20px;
		}
		.ls-nav .logo img{
			height: 40px;
		}
		.ls-nav-bar .enter-btn-box .enter-btn{
			width: 100px;
			height: 40px;
			font-size: 18px;
			background-size:100px 40px;
		}
		.ls-nav-bar .h5_menu{
			width: 60px;
			height: 40px;
			background-size:60px 40px;
		}
	}
</style>